import React from 'react'
import moment from 'moment';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import useLang from '../../hooks/useLanguage';


/**
 * @description component which creates booking cards 
 * show bookings list
 * @author Jagannath
 * @date 2020-09-15
 * @param data bookings list {data: {data:[--]}}
 * @param openProfileDialog method to call open profile dialog
 * @return {*} bookingHandler method
 */
const [lang] = useLang();
export const bookingsHandler = (data, openProfileDialog) => {
    // checking for booking exiting
    if (!data){
        return <p className="text-center text-muted">{lang.NoAppoinmentdate}</p>
    }
    return (
        !data.data.length && !data.isLoader
            ?<p className="text-center text-muted">{lang.NoAppoinmentdate}</p>

            :data.data.map((value, index)=>{
                return  (
                    <div key={index} className="p-0 m-0 col-12 row">
                        <div className="p-0 col-2 pt-3">
                            <p className="booking-time">{
                                moment(value.bookingRequestedFor*1000).format('LT')
                            }</p>
                        </div>
                        <div className="card mt-2 col-10 p-0">
                            <div className="card-body">
                                {value.profilePic ? 
                                    (<img 
                                        src={value.profilePic}
                                        alt="icon"
                                        className="float-left rounded-circle" />)
                                    :(<span className="float-left rounded-circle text-img">
                                        {value.firstName[0] + value.lastName[0]}
                                    </span>)
                                }
                                <div className="bookings">
                                    <h6 
                                    onClick={() => openProfileDialog(value)}
                                    className="card-subtitle mt-2 ml-3 text-muted">
                                        {value.firstName + ' ' + value.lastName}
                                    </h6>
                                </div>
                                <MoreHorizIcon 
                                    className="button-menu-icon" 
                                    onClick={() => openProfileDialog(value)}
                                    style={{width:'80px', height:'40px'}}/>
                            </div>
                        </div>
                    </div>
                )
            })
    )
}
