import { Button, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import './NextVisitForm.css'
import useLang from '../../hooks/useLanguage'
export class NextVisitForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             visitAfter: '',
             visitUnit: 'Days',
             appointmentMemo: ''
        }
    }

    /**
     * @description submitting the form
     * @author Jagannath
     * @date 2020-09-18
     * @param e form event
     */
    submitForm = (e) =>{
        e.preventDefault()
        console.log(this.state);
        this.props.formSubmit(this.state)
    }


    /**
     * @description initial lifecycle after component mount
     * setting editing data to state
     * @author Jagannath
     * @date 2020-09-18
     */
    componentDidMount(){
        const [lang] = useLang();
        const {editText} = this.props
        this.setState({
            visitAfter: editText.visitAfter || '',
            visitUnit: editText.visitUnit || `${lang.Days}`,
            appointmentMemo: editText.appointmentMemo || ''
        })
    }
    
    render() {
        const [lang] = useLang();
        const addForm = this.state;
        return (
            <div className="visit-form p-3 pb-5 pt-5">
                <form className="col-12 row pl-5">
                    <div className="col-12 text-left p-0">
                        <h6 className="bold">{lang.Addrecomendedvisitdateafter}</h6>
                    </div>
                    <div className="col-12 row">
                        <TextField
                            id="outlined-number"
                            type="number"
                            placeholder="7"
                            value={addForm.visitAfter}
                            onChange={(e)=>this.setState({visitAfter: e.target.value})}
                            className="col-md-5 mr-5 mt-2 mb-2"
                            variant="outlined" />
                        <TextField 
                            id="outlined-number"
                            placeholder={lang.Days}
                            value={addForm.visitUnit}
                            onChange={(e)=>this.setState({visitUnit: e.target.value})}
                            className="col-md-5 mt-2 mb-2 float-right"
                            variant="outlined" 
                            InputProps={{
                                readOnly: true
                            }} />
                    </div>

                    <div className="col-12 text-left mt-3 p-0">
                        <h6 className="bold">{lang.Appointmentmemo}</h6>
                    </div>
                    <div className="col-md-12 pl-0 pr-1">
                        <TextField 
                            id="outlined-multiline-static" 
                            className="col-md-11"
                            placeholder="Add general comments..."
                            value={addForm.appointmentMemo}
                            onChange={(e)=>this.setState({appointmentMemo: e.target.value})}
                            rows={6}
                            multiline
                            variant="outlined" />
                    </div>
                    <div className="col-12 mt-3 row">
                        <Button
                            variant="outlined"
                            color="primary"
                            className="m-auto pl-5 pr-5"
                            onClick={this.submitForm}
                            type="submit">
                            {lang.Save}
                        </Button>
                    </div>
                </form>
            </div>
        )
    }
}

export default NextVisitForm
