import { Avatar, Button, Card } from '@material-ui/core'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { reactLocalStorage } from 'reactjs-localstorage'
import Header from '../header/Header'
import './style.css';
import CompleteCallImg from '../../static/images/app_images/complete-call.png'
import { providerBookingById, customerBookingById, getConferenceToken } from '../../services/service'
import { baseUrl } from '../../global/global'
import useLang from '../../hooks/useLanguage';
/**
 * @description Component for showing Thankyou notice after call disconnects
 * @author Jagannath
 * @date 2020-09-15
 * @export ThankYouPage component
 * @class ThankYouPage 
 */
export class ThankYouPage extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             bookingData: {},
             bookingId: reactLocalStorage.get('bookingId')
        }
    }
    

    /**
     * @description showing prescription opetion for doctor only
     * @author Jagannath
     * @date 2020-09-18
     */
    PrescriptionPage = () =>{
        const [lang] = useLang();
        if(reactLocalStorage.get('userType') == 1){
            return (
                <Link to="/prescription">
                    {lang.CreatePrescription}
                </Link>
            )
        }else{
            return null
        }
    }
    componentDidMount(){
        if(reactLocalStorage.get('userType')==1){
            providerBookingById(this.state.bookingId).then(
                (res)=>{
                    this.setState({bookingData: res.data.data})
                }
            ).catch(err=>console.log(err))
        }else{
            customerBookingById(this.state.bookingId).then(
                (res)=>{
                    this.setState({bookingData: res.data.data})
                }
            ).catch(err=>console.log(err))
        }
    }

    restartConference = () => {
        getConferenceToken(this.state.bookingId)
            .then(async (res) => {
                if(reactLocalStorage.get('userType') == 2){
                    const isCallActive = await this.getCallStatus(this.state.bookingId)
                    if(isCallActive){
                        reactLocalStorage.set('conferenceToken', res.data.data.token)
                        window.location = (`${baseUrl}conference`)
                    }else{
                        window.alert('Wait for the Doctor to start meeting')
                    }
                }else{
                    reactLocalStorage.set('conferenceToken', res.data.data.token)
                    window.location = (`${baseUrl}conference`)
                }
            })
            .catch((err) => {
                console.log(err);
                // this.setState({ isBackdrop: false })
            })
    }
    
    render() {
        const Data = this.state.bookingData;
        const [lang] = useLang();
        console.log(Data)
        return (
            <div className="thankyou-container">
                <Header className="app-header" />
                <hr className="m-0" />
                <Card className="thankyou-card" >
                    <div className="body-image p-3">
                        <img src={CompleteCallImg} height="100%" alt="Joiibmed"/>
                    </div>  
                    <h4 className="bold v-color">{lang.callcompleted}</h4>
                    <h4 className="bold v-color">{lang.ThankyouJoiibmed}</h4>
                    <div className="d-flex justify-content-center p-color">
                        <p>{lang.CallDuration}: </p>
                        <p>{' ' +' 01:20:34' }</p>
                    </div>
                    
                    <this.OpponentUserProfile Data={Data}/>
                    <this.DoctorPrescriptionButton />
                </Card>
            </div>
        )
    }

    /**
     * @description showing profile of opponent user 
     * @author Jagannath
     * @date 2020-09-30
     * @param props booking details
     */
    OpponentUserProfile = (props) =>{
        if (reactLocalStorage.get('userType')==1){
            const Data = props.Data;
            return (
                <div className="patient-view d-flex justify-content-center pt-3">
                    <Avatar
                        alt={Data.firstName || 'Patient'} 
                        src={Data.profilePic} 
                        className={'thankyou-avatar'} 
                        >
                        {Data.firstName || 'P'}
                    </Avatar>
                    <div className="pl-3">
                        <h5 className="bold v-color m-0">{Data.firstName + ' ' + Data.lastName}</h5>
                        <p className="text-left p-color">
                            {Data.gender || ''}
                            {Data.gender && Data.age ? ', ': ''}
                            {Data.age ? Data.age + ' Years' : ''}
                        </p>
                    </div>
                </div>
            )
        }else{
            const providerData = props.Data && props.Data.providerDetail ? props.Data.providerDetail : {}
            const Data = props.Data
            return(<div className="patient-view d-flex justify-content-center pt-3">
                    <Avatar
                        alt={providerData.profilePic || 'Doctor'} 
                        src={providerData.profilePic} 
                        className={'thankyou-avatar'} 
                        >
                        {providerData.firstName || 'P'}
                    </Avatar>
                    <div className="pl-3">
                        <h5 className="bold v-color m-0">
                            {providerData.title + ' ' + providerData.firstName + ' ' + providerData.lastName}
                        </h5>
                        <p className="text-left p-color">
                            { Data.degree || ''} 
                        </p>
                    </div>
                </div>
            )
        }
    }

    /**
     * @description showing create prescription and restart button for doctor
     * @author Jagannath
     * @date 2020-09-30
     */
    DoctorPrescriptionButton = () =>{
        const [lang] = useLang();
        if(reactLocalStorage.get('userType')==1){
            return (
                <div className="p-5">
                    <Button 
                        variant="outlined" 
                        className="mr-2 bold" 
                        size="large"
                        // onClick={()=>window.history.go(-1)}
                        onClick={this.restartConference}
                        color="primary" >
                       {lang.RestartTeleCall}
                    </Button>
                    <Link className="ml-2 link" to="/prescription">
                        <Button 
                            variant="contained" 
                            className="bold" 
                            size="large"
                            color="primary" >
                            {lang.CreatePrescription}
                        </Button>
                    </Link>
                </div>
            )
        }else{
            return (<></>)
        }
    }
}

export default ThankYouPage
