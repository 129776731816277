import LOGO from '../static/images/app_images/appscrip.png'
import WEBP_1 from '../static/images/dummy/1.webp'
import { getCookie } from './session'
export const APP_NAME = 'Appscrip'
export const MAIN_LOGO = LOGO

export const WEBP_DUMMY_1 = WEBP_1

// OG DATA VARS
export const OG_IMAGE = 'https://www.appscrip.com/wp-content/uploads/2019/03/Group-4612.png'

export const API_HOST = 'https://api.joiibmed.com/'
export const defaultLanguage = getCookie('lang') || 'en'
