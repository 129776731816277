import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Homepagepng from '../../static/images/app_images/homepage.png'
import JoiibmedIcon from '../../static/images/app_images/JoiibmedIcon.png';
import JoiibmedIconDoctor from '../../static/images/app_images/JoiibmedIconDoctor.png';
import {
  Typography,
  Grid,
  Avatar,
  Button,
  CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Snackbar
  } from '@material-ui/core';
  import { Alert } from '@material-ui/lab';
  import {reactLocalStorage} from 'reactjs-localstorage';
  import { Redirect, Link } from 'react-router-dom';
  import { doctorLogin, patientLogin } from '../../services/auth';
  import './Login.css'
  import { setCookie } from '../../lib/session';
  import useLang from '../../hooks/useLanguage';
  import LanguageSelect from './langDropdown';
  
  
  /**
 * @description functional component style object
 * @author Jagannath
 * @date 2020-09-15
 */
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    paddingTop: '50px',
    paddingLeft: '32px'
  },
  image: {
    backgroundImage: 'url(https://imgur.com/kZKKltQ.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  drop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '70%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  homepagelogo: {
    width: "80%",
    marginTop: '60px',
    marginLeft: '40px'
  }
}));

/**
 * @description Login component 
 * for both doctor and patients
 * @author Jagannath
 * @date 2020-09-15
 * @export DoctorLogin
 * @return {*} component
 */
export default function DoctorLogin() {
  const [lang] = useLang();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [auth, setAuth] = useState(false);
  const [userType, setUserType] = useState('Doctor');
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  // useEffect(() => {
  // reactLocalStorage.set('userType',1)
  // }, [])

  /**
   * @description submitting form and logging in user
   * @author Jagannath
   * @date 2020-09-15
   * @param e submit event
   * @return {*} 
   */
  const formSubmitHandler = async(e)=>{
    if( email && password ) {
      if(userType === 'Doctor'){

        /** login payload for doctor */
        let payload = {
          "mobileOrEmail": email,
          "password": password,
          "deviceType": 3,
          "deviceId":"vaidg@gmail.com"
        }
        doctorLogin(payload)
          .then((res)=>{
            const data = res.data.data;
            reactLocalStorage.set('token', data.token.accessToken);
            reactLocalStorage.set('refreshToken', data.token.refreshToken);
            reactLocalStorage.set('accessExpireAt', data.token.accessExpireAt);
            reactLocalStorage.set('email', data.email);
            reactLocalStorage.set('userId', data.id);
            reactLocalStorage.set('privateKey', data.privateKey);
            reactLocalStorage.set('status',data.status);
            reactLocalStorage.set('userStatus',data.status);
            reactLocalStorage.set('userType',1);
            reactLocalStorage.set('fcmTopic', data.fcmTopic)
            setCookie('profilePic', data.profilePic);
            setCookie('username', (data.firstName + ' ' + data.lastName) );
            setCookie('email', data.email);
            setCookie('userId', data.id);
            setAuth(true)
          })
        .catch((err)=>{
          console.log(err)
          reactLocalStorage.clear();
          setAuth(false)
          setErrorMsg('The entered user is not registered with us as a doctor.')
          setOpen(true)
        })
      }else{
        /** login payload for patients */
        let payload = {
          "emailOrPhone": email,
          "password": password,
          "devType": 3,
          "deviceId":"vaidg@gmail.com",
          "loginType":1
        }
        patientLogin(payload)
          .then( (res)=>{
            const data = res.data.data;
              reactLocalStorage.set('token', data.token.accessToken);
              reactLocalStorage.set('refreshToken', data.token.refreshToken);
              reactLocalStorage.set('accessExpireAt', data.token.accessExpireAt);
              reactLocalStorage.set('email', data.email);
              reactLocalStorage.set('userId', data.sid);
              reactLocalStorage.set('privateKey', data.privateKey);
              reactLocalStorage.set('userType',2);
              setCookie('profilePic', data.profilePic);
              setCookie('username', (data.firstName + ' ' + data.lastName) );
              setCookie('email', data.email);
              setCookie('userId', data.sid);
              setAuth(true);
          })
        .catch((err)=>{
          console.log(err)
          reactLocalStorage.clear();
          setAuth(false);
          setErrorMsg('The entered user is not registered with us as a patient.')
          setOpen(true)
        })
      }
    }else{
      setErrorMsg('Missing Username or Password field')
      setOpen(true)
    }
  }

  /** checking for token and if then sending to home page */
  if(reactLocalStorage.get('token')){
      console.log(auth)
      return <Redirect to="/home" />
  }
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7}>
          <div>
              <img src={userType === 'Doctor' ? JoiibmedIconDoctor : JoiibmedIcon} alt="" width="150" height="50" />
          </div>
          <div>
              <img src={Homepagepng} alt="" className={classes.homepagelogo} />
          </div>
      </Grid>
      <Grid item xs={12} sm={8} md={5} elevation={6} square>
      <div className={classes.drop}>
             <LanguageSelect/>
              </div>
          <div className={classes.paper}>
            
          <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
              {lang.Signin}
          </Typography>
          <div className="mt-3">
            <Button 
              color="primary"
              className="mr-1"
              onClick={()=>{setUserType('Patient'); reactLocalStorage.set('userType',2)}}
              variant={userType === 'Patient' ? 'contained' : "outlined"}>
            {lang.Patient}
            </Button>
            <Button 
              color="primary"
              className="ml-1"
              onClick={()=>{setUserType('Doctor');reactLocalStorage.set('userType',1)}}
              variant={userType === 'Doctor' ? 'contained' : "outlined"}>
              {lang.Doctor}
            </Button>
          </div>
          <form className={classes.form} noValidate>
              <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={lang.EmailAddress}
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
              autoFocus
              />
              <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={lang.Password}
              type="password"
              id="password"
              value={password}
              onChange={(e)=>setPassword(e.target.value)}
              autoComplete="current-password"
              />
              <Grid container>
              <Grid item xs>
                  <div variant="body2">
                  <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label={lang.Rememberme}
                  />
                  </div>
              </Grid>
              <Grid item>
                  <Link to="/forgot" variant="body2">
                  {lang.Forgotpassword}
                  </Link>
              </Grid>
              </Grid>

              <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={formSubmitHandler}
              >
              {lang.Signin}
              </Button>
              
          </form>
          </div>
      </Grid>

      {/* snackbar for showing alert messages */}
      <Snackbar open={open} autoHideDuration={8000} onClose={()=>setOpen(false)}>
          <Alert onClose={()=>setOpen(false)} severity="error">
              {errorMsg}
          </Alert>
      </Snackbar>
      </Grid>
  );
}