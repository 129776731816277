import { Card, CardHeader } from '@material-ui/core'
import React, { Component } from 'react'
import './RecentBookingCard.css'
import moment from 'moment'
import useLang from '../../hooks/useLanguage'

export class RecentBookingCard extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    render() {
        const booking = this.props.data
        const [lang] = useLang();
        return (
            <div>
                <Card className="rb-card p-2 mt-2 mr-2">
                    <div className="profile-header">
                        <button 
                            type="button"
                            className="btn btn-default tele-booking-btn btn-sm">
                                {
                                    booking.callType == 3 ? 'Tele Appointment' :
                                    booking.callType == 2 ? `${lang.clinicvisit}`:
                                    `${lang.doctorhome}`
                                }
                        </button>
                    </div>
                    <div className="recent-body pt-3 d-flex">
                        <img 
                            className="recent-profile-icon"
                            src={booking.profilePic || 'https://servicegenie.s3.ap-south-1.amazonaws.com/admin/1599898981677.jpg'}
                            alt="P" />
                        <div className="profile-body-header pl-2">
                            <h6 className="bold m-0">
                                {booking.firstName + ' '}
                                {booking.lastName}
                            </h6>
                            <p className="text-muted profile-date-time">
                                { moment(booking.bookingCompletedAt*1000).format('D'+' '+'MMMM'+' '+'YYYY' ) }
                                {' | '}
                                { moment(booking.bookingCompletedAt*1000).format('hh:mm A') }
                            </p>
                        </div>
                    </div>
                    <hr className="mt-2 mb-2" />
                    <div className="recent-footer d-flex justify-content-between pr-2">
                        <p className="booking-cost bold m-0">
                            {booking.currencySymbol || booking.currency}
                            {booking.accounting && booking.accounting.total ? booking.accounting.total : 0}
                        </p>
                        <a 
                            className="invoice-btn m-0 bold" 
                            href={booking.pdfFile}
                            target="_blank" >
                            {booking.statusMsg }
                        </a>
                    </div>
                </Card>
            </div>
        )
    }
}

export default RecentBookingCard
