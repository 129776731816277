const en = require("./translations/en.json");
const pt = require("./translations/pt.json");
export const languageSet ={
    en,
    pt
}
export const languageArray=[
    {
    name: "English",
    code: "en"
},
{
    name: "Portuguese ",
    code: "pt"
}
];