import React, { Component } from 'react'
import { reactLocalStorage } from 'reactjs-localstorage'
import Header from '../header/Header'
import { providerBookingById } from '../../services/service';
import './Prescription.css'
import { 
    Button, 
    Card, 
    CardHeader, 
    CircularProgress, 
    Dialog, 
    Divider, 
    FormControl, 
    IconButton, 
    TextField ,
    Tooltip
} from '@material-ui/core';
import MedicationForm from '../medication-form/MedicationForm.js';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import NextVisitForm from '../next-visit-form/NextVisitForm';
import { completeAppointment, deleteMedication, getMedications } from '../../services/prescriptionService';
import moment from 'moment';
import RatingReview from '../Ratings-review/RatingReview';
import Loader from '../../components/Loader/Loader';
import useLang from '../../hooks/useLanguage';

export class Prescription extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             bookingData:{},
             openDialog: false,
             editTextId: null,
             nextVisitDialog: false,
             nextVisit: {
                visitAfter: '',
                visitUnit: '',
                appointmentMemo: ''
             },
             prescriptionList: [],
             showRatingDialog: false,
             isLoader:false
        }
    }

    /**
     * @description getting session details by provider
     * @author Jagannath
     * @date 2020-09-18
     */
    getSessionDetails = () => {
        const bookingId = reactLocalStorage.get('bookingId')
        providerBookingById(bookingId).then(
            (res)=>{
                console.log(res.data.data)
                
                this.setState({bookingData: res.data.data})
            }
        )
        .catch(err=>{
            console.log(err)
        })
    }

    componentDidMount(){
        this.getSessionDetails();
        this.getAllMedications();
        
        setTimeout(() => {
            if(this.state.bookingData.status == 10){
                this.setState({showRatingDialog: true, isLoader: false})
            }
        }, 1000);
    }

    /**
     * @description method to call api for get all medications for this booking id
     * and updating state 
     * @author Jagannath
     * @date 2020-09-28
     */
    getAllMedications(){
        this.setState({isLoader: true})
        getMedications(reactLocalStorage.get('bookingId'))
            .then((res)=>{
                console.log(res)
                this.setState({
                    prescriptionList: res.data.data,
                    isLoader: false
                })
            })
            .catch((err)=>{
                console.log(err);
                this.setState({isLoader: false})
            })
    }

    /**
     * @description method to open/close medication dialog
     * @author Jagannath
     * @date 2020-09-17
     */
    handleClickOpen = () =>{
        this.setState({
            openDialog: !this.state.openDialog,
            editTextId: null
        })
    }

    /**
     * @description medication form submit handler
     * getting data from dialog and setting to state
     * @author Jagannath
     * @date 2020-09-18
     * @param data form data
     */
    formHandler = async() =>{
        await this.getAllMedications()
        this.handleClickOpen()
    }

    /**
     * @description opening prescription dialog
     * @author Jagannath
     * @date 2020-09-18
     */
    prescriptionDialog = () =>{
        return (
            <Dialog
                open={this.state.openDialog}
                onClose={this.handleClickOpen}
                aria-labelledby="edit-apartment"
                disableBackdropClick>
                    <MedicationForm 
                        formData={this.formHandler} 
                        handleClose={this.handleClickOpen}
                        editText={this.state.prescriptionList.filter(item=>item.id == this.state.editTextId)[0]}/>
                </Dialog>
        )
    }

    /**
     * @description showing prescription medications 
     * @author Jagannath
     * @date 2020-09-18
     */
    PrescriptionList = () => {
        const [lang] = useLang();
        if(!this.state.prescriptionList.length){
            return (
                <h4 className="text-muted" style={{margin: "10px auto"}}>{lang.NoMedicationadded}</h4>
            )
        }
        return this.state.prescriptionList.map((item, i)=>{
            return(
                <div className="border rounded pt-3 prescription-item m-2 d-flex justify-content-start" key={i}>
                    <div className="col-8">
                        <div className="d-flex">
                            <b className="pr-1">{i+1+'. '}</b>
                            <p className="pr-1 text-muted c-name">{lang.CompoundName}: </p>
                            <b>{' ' +item.compoundName}</b>
                        </div>
                        <div className="d-flex">
                            <p className="pr-1 text-muted">{lang.Doeses}: </p>
                            <b>{' ' +item.drugStrength + item.drugUnit}</b>
                        </div>
                        <div className="d-flex">
                            <p className="pr-1 text-muted">{lang.Frequency}: </p>
                            <b>{' ' +item.frequency}</b>
                        </div>
                    </div>
                    <div className="col-4 d-flex mt-auto">
                        <IconButton 
                            aria-label="delete" 
                            color="primary" 
                            title="Edit medication"
                            onClick={()=>this.onEditMedication(item.id)}
                            className="icon-border">
                            <EditIcon />
                        </IconButton>
                        <Tooltip title="Delete Medication" arrow>
                            <IconButton 
                                aria-label="delete" 
                                color="secondary"
                                onClick={()=>this.onRemoveMedication(item.id, i)}
                                className="icon-border ml-2">
                                <DeleteOutlineIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            )
        })
    }

    /**
     * @description called on clicking edit button
     * setting clicked data to state and trying to open dialog
     * @author Jagannath
     * @date 2020-09-18
     * @param editId id of editing medication
     */
    onEditMedication = (editId) => {
        this.setState({
            editTextId: editId,
            openDialog: true
        })
    } 

    /**
     * @description removing a medication from prescription list of medications from state
     * @author Jagannath
     * @date 2020-09-18
     * @param index index of item in prescription list
     */
    onRemoveMedication = (mId, index) => {
        const prescriptionListInstance = this.state.prescriptionList;
        prescriptionListInstance.splice(index,1)

        deleteMedication(reactLocalStorage.get('bookingId'), mId)
        .then((res)=>{
            console.log('medication deleted', res);
            this.setState({prescriptionList: prescriptionListInstance})
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    /**
     * @description submit handler for next visit form
     * @author Jagannath
     * @date 2020-09-18
     * @param data form data
     */
    nextVisitHanler = (data) => {
        this.setState({nextVisit: data, nextVisitDialog:false});
    }
    
    /**
     * @description showing next visit form inside a dialog
     * @author Jagannath
     * @date 2020-09-18
     */
    NextVisitForm = () =>{
        const [lang] = useLang();
        return (
            <FormControl className="form-control col-md-12 float-right mt-5">
                    
                <h6 className="col-12 h5-next bold text-left p-0">{lang.Addrecomendedvisitdateafter}</h6>
                <Button 
                    className="p-0 bg-white" 
                    onClick={()=>this.setState({nextVisitDialog:true})}>
                    <TextField
                        id="standard-textarea"
                        placeholder="Next Recomended Visit after"
                        className="col-md-12"
                        value={
                            this.state.nextVisit.visitAfter ?
                            this.state.nextVisit.visitAfter + ' ' + this.state.nextVisit.visitUnit
                            : ''
                        }
                        InputProps={{
                            readOnly: true,
                            }}
                        />
                </Button>
            <Dialog
                open={this.state.nextVisitDialog}
                onClose={()=>this.setState({nextVisitDialog:false})}
                aria-labelledby="edit-apartment" >
                    <NextVisitForm 
                        formSubmit={this.nextVisitHanler}
                        editText={this.state.nextVisit}
                        />
            </Dialog>
            </FormControl>
        )
    }
    
    /**
     * @description Rendering component
     * @author Jagannath
     * @date 2020-09-18
     * @return {*} 
     */
    render() {
        const customer = this.state.bookingData;
        const [lang] = useLang();
        return (
            <div className="prescription-container">
                <Header className="prescription-header"/>
                <Card className="prescription-body-card">
                    <hr className="m-0 p-0"/>
                    <div className="prescription-body">
                        <div>
                            <CardHeader
                                className="mr-0 pr-0"
                                avatar={<img src={customer.profilePic || 'https://imgur.com/fK2WyMw.png'} alt="P" className="profile-icon"/>}
                                action={
                                    <button 
                                        className="btn btn-outline-info"
                                        onClick={this.handleClickOpen}>
                                        {`+ ${ lang.Addmedication}`}
                                    </button>
                                }
                                title={(customer.firstName || 'Patient')+' '+(customer.lastName || '')}
                                subheader={
                                    (customer.age ? customer.age + ', ': '') + (customer.gender || '')}
                            />
                        </div>
                        <this.prescriptionDialog />
                        <div>
                            <Divider />
                            <div className="prescription-list pt-3">
                                <div className="d-flex flex-wrap" >
                                    <this.PrescriptionList />
                                </div>
                            </div>

                        </div>

                        <div className="next-visit">
                            <this.NextVisitForm />
                        </div>
                        <div className="prescription-footer text-center">
                            <Button 
                                className="m-5" 
                                variant="outlined"
                                onClick={this.completeAppointmentHandler}
                                color="primary" >
                               {lang.CompleteAppointment}
                            </Button>
                        </div>
                    </div> 
                </Card>
                <this.ReviewRatingDialog />
                <Loader isLoader={this.state.isLoader} />
            </div>
        )
    }

    /**
     * @description method to submit 
     * @author Jagannath
     * @date 2020-09-29
     */
    completeAppointmentHandler = () => {
        this.setState({isLoader: true})
        if(this.state.bookingData.status && ( this.state.bookingData.status == 9 || this.state.bookingData.status == 10 )){
            this.setState({showRatingDialog:true, isLoader: false});
            return;
        }
        let list = {
            bookingId: reactLocalStorage.get('bookingId'),
            status: 9,
            latitude: this.state.bookingData.latitude,
            longitude: this.state.bookingData.longitude,
            appointmentMemo: this.state.nextVisit.appointmentMemo
        }
        if(this.state.nextVisit.visitAfter){
            list.nextVisitDate = moment().add(this.state.nextVisit.visitAfter, 'day').valueOf()
        }
        completeAppointment(list).then(
            (res)=>{
                console.log(res)
                this.setState({
                    showRatingDialog:true,
                    isLoader: false
                });
            }
        ).catch(err=>{
            console.log(err)
        })
    }


    /**
     * @description opening prescription dialog
     * @author Jagannath
     * @date 2020-09-18
     */
    ReviewRatingDialog = () =>{
        return (
            <Dialog
                open={this.state.showRatingDialog}
                onClose={()=>{this.setState({showRatingDialog:false})}}
                aria-labelledby="edit-apartment"
                disableBackdropClick>
                    <RatingReview 
                        handleClose={()=>this.setState({showRatingDialog:false})}
                        bookingData={this.state.bookingData}/>
                </Dialog>
        )
    }
}

export default Prescription
