import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Homepagepng from '../../static/images/app_images/homepage.png'
import JoiibmedIcon from '../../static/images/app_images/JoiibmedIcon.png'
import {
    Typography,
    Grid,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {reactLocalStorage} from 'reactjs-localstorage';
import { Redirect, Link } from 'react-router-dom';
import { forgotPasswordCustomer, forgotPasswordProvider } from '../../services/auth';
import useLang from '../../hooks/useLanguage';

/**
 * @description functional component style object
 * @author Jagannath
 * @date 2020-09-15
 */
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    paddingTop: '50px',
    paddingLeft: '32px'
  },
  image: {
    backgroundImage: 'url(https://imgur.com/kZKKltQ.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '70%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  homepagelogo: {
    width: "80%",
    marginTop: '60px',
    marginLeft: '40px'
  },
  errMsg: {
    marginBottom: '-20px !important',
    marginTop: '45px !important',
    color: 'tomato'
  }
}));

/**
 * @description component for forgot password
 * @author Jagannath
 * @date 2020-09-15
 * @export ForgotPassword component
 * @return {*} component
 */
export default function ForgotPassword() {
  const [lang] = useLang();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [showToast, setToast] = React.useState(false);


  /**
   * @description submitting form
   * sending email to user if valid user
   * @author Jagannath
   * @date 2020-09-15
   * @param e form event
   */
  const formSubmitHandler = async(e)=>{
    e.preventDefault()
    if( email ) {
      if(parseInt(reactLocalStorage.get('userType')) === 1){
        /** payload for sending emiail to doctor */
        let payload = {
            "emailOrPhone": email,
            "countryCode": "+91",
            "userType": 1,
            "type": 2
        }
        forgotPasswordProvider(payload)
          .then( async (res)=>{
              setErrMsg('')
              setSuccessMsg('Email sent!\nPlease check your email.')
              setToast(true)
          })
          .catch((err)=>{
              console.log(err)
              setErrMsg('Please enter a valid Doctor Email Address!')
          })
      }else{
        /** payload for sending emiail to patients */
        let payload = {
          "emailOrPhone": email,
          "countryCode": "+91",
          "userType": 2,
          "type": 2
        }
        forgotPasswordCustomer(payload)
          .then( async (res)=>{
            setErrMsg('')
            setSuccessMsg('Email sent!\nPlease check your email.');
            setToast(true)
          })
          .catch((err)=>{
            console.log(err)
            setErrMsg('Please enter a valid Customer Email Address!')
          })
      }
    }else{
        setErrMsg('Please enter a valid Email Address!')
    }
  }

  /** checking for valid token if then sending user to home page */
  if(reactLocalStorage.get('token')){
      return <Redirect to="/home" />
  }
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7}>
          <div>
              <img src={JoiibmedIcon} alt="" />
          </div>
          <div>
              <img src={Homepagepng} alt="" className={classes.homepagelogo} />
          </div>
      </Grid>
      <Grid item xs={12} sm={8} md={5} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {lang.Signin}
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
              autoFocus
            />
              
            <Grid container>
              <Grid item xs></Grid>
              <Grid item>
                <Link to="/login" variant="body2">
                  {"Go back to login page!"}
                </Link>
              </Grid>
            </Grid>
            <p className={classes.errMsg}>
              {errMsg}
            </p>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={formSubmitHandler}
              >
              {lang.SendEmail}
            </Button>
          </form>
        </div>
      </Grid>

      {/* showing snack bar for success message */}
      <Snackbar open={showToast} autoHideDuration={8000} onClose={()=>setToast(true)}>
        <Alert onClose={()=>setToast(false)} severity="success">
            {successMsg}
        </Alert>
      </Snackbar>
    </Grid>
  );
}