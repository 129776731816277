import axios from 'axios'
// import { getCookie } from './session'
import * as enVariables from './config'
import { baseUrl } from '../global/global'

// global API HOST ( API Domain URL)
const API_HOST = enVariables.API_HOST
const headers = { 'Content-Type': 'application/json', 'lan': 1, 'platform':'website' }
// Merging a full URL - for e.g. ->   "https://wwww.apiurl.com"  + "/users"
const getUrl = endpoint => API_HOST + endpoint

/**
 * @description Making a POST request (without token), 
 * use this function for all POST requests in project
 * @author Jagannath
 * @date 2020-09-16
 * @param data payload for POST method
 * @param endpoint endpoint to call
 * @return {*} api response
 */
export const post = async (endpoint, data) => {
  return axios.post(getUrl(endpoint), data, {
    headers
  })
  .then((res)=>{
    return res
  })
  .catch((err) => {
    // if(err.response.status && err.response.status === 401){
    //   localStorage.clear()
    //   window.location = `${baseUrl}login`
    // }
    // throw err
  })
}

/**
 * @description Making a PATCH request (without token), 
 * use this function for all PATCH requests in project
 * @author Jagannath
 * @date 2020-09-16
 * @param data payload for PATCH method
 * @param endpoint endpoint to call
 * @return {*} api response
 */
export const patch = async (endpoint, data) => {
  return axios.patch(getUrl(endpoint), data, {
    headers
  })
  .then((res)=>{
    return res
  })
  .catch((err) => {
    // if(err.response.status && err.response.status === 401){
    //   localStorage.clear()
    //   window.location = `${baseUrl}login`
    // }
    throw err
  })
}

/**
 * @description Making a GET request, 
 * use this function for all GET requests in project
 * @author Jagannath
 * @date 2020-09-16
 * @param endpoint endpoint to call
 * @return {*} api response
 */
export const get = async (endpoint) => {
  axios.defaults.headers.common['authorization'] = await window.localStorage.getItem('token')
  return axios.get(getUrl(endpoint), {
    headers
  })
  .then((res)=>{
    return res
  })
  .catch((err) => {
    if(err.response.status && err.response.status === 401){
      localStorage.clear()
      window.location = `${baseUrl}login`
    }
    // if(err.response.status && err.response.status === 406){
    //   window.alert('Session Expired!')
    //   localStorage.clear()
    //   window.location = `${baseUrl}login`
    // }
    throw err
  })
}

/**
 * @description Making a POST request (with token), 
 * use this function for all POST requests in project
 * @author Jagannath
 * @date 2020-09-16
 * @param data payload for POST method
 * @param endpoint endpoint to call
 * @return {*} api response
 */
export const postWithToken = async (endpoint, data) => {
  axios.defaults.headers.common['authorization'] = await window.localStorage.getItem('token')
  return axios.post(getUrl(endpoint), data, {
    headers
  })
  .then((res)=>{
    return res
  })
  .catch((err) => {
    // if(err.response.status && err.response.status === 401){
    //   localStorage.clear()
    //   window.location = `${baseUrl}login`
    // }
    throw err
  })
}

/**
 * @description Making a PATCH request (with token), 
 * use this function for all PATCH requests in project
 * @author Jagannath
 * @date 2020-09-16
 * @param data payload for PATCH method
 * @param endpoint endpoint to call
 * @return {*} api response
 */
export const patchWithToken = async (endpoint, data) => {
  axios.defaults.headers.common['authorization'] = await window.localStorage.getItem('token')
  return axios.patch(getUrl(endpoint), data, {
    headers
  })
  .then((res)=>{
    return res
  })
  .catch((err) => {
    // if(err.response.status && err.response.status === 401){
    //   localStorage.clear()
    //   window.location = `${baseUrl}login`
    // }
    throw err
  })

}


/**
 * @description Making a PUT request (with token), 
 * use this function for all PUT requests in project
 * @author Jagannath
 * @date 2020-09-16
 * @param data payload for PUT method
 * @param endpoint endpoint to call
 * @return {*} api response
 */
export const putWithToken = async (endpoint, data) => {
  axios.defaults.headers.common['authorization'] = await window.localStorage.getItem('token')
  return axios.put(getUrl(endpoint), data, {
    headers
  })
  .then((res)=>{
    return res
  })
  .catch((err) => {
    // if(err.response.status && err.response.status === 401){
    //   localStorage.clear()
    //   window.location = `${baseUrl}login`
    // }
    throw err
  })
}

/**
 * @description Making a DELETE request (with token), 
 * use this function for all DELETE requests in project
 * @author Jagannath
 * @date 2020-09-16
 * @param endpoint endpoint to call
 * @return {*} api response
 */
export const deleteReq = async (endpoint) => {
  axios.defaults.headers.common['authorization'] = await window.localStorage.getItem('token')
  return axios.delete(getUrl(endpoint), {
    headers
  })
  .then((res)=>{
    return res
  })
  .catch((err) => {
    // if(err.response.status && err.response.status === 401){
    //   localStorage.clear()
    //   window.location = `${baseUrl}login`
    // }
    throw err
  })
}

/**
 * @description Making a GET request, 
 * use this function for all GET requests in project
 * @author Jagannath
 * @date 2020-09-16
 * @param data payload for PATCH method
 * @param token basic auth token
 * @param endpoint endpoint to call
 * @return {*} api response
 */
export const postWithBasicAuth = async (endpoint, data, token) => {
  return axios.post(getUrl(endpoint), data,{
    headers: { 
      'lan': 1, 
      'platform':'website',
      'authorization': token,
      'Content-Type': 'application/json'
    }
  })
  .then((res)=>{
    return res
  })
  .catch((err) => {
    // if(err.response.status && err.response.status === 401){
    //   localStorage.clear()
    //   window.location = `${baseUrl}login`
    // }
    throw err
  })
}

