// vaidG caliing url for doctor

// export const doctorCallingUrl = 'https://doctor.vaidg.com/';
export const doctorCallingUrl = 'https://meet.joiibmed.com/';
export const baseUrl = 'https://joiibmed.com/'

// export const baseUrl = 'http://localhost:3000/'
// export const baseUrl = 'http://10.10.10.114:3000/'

// MQTT config
export const mqttHost = 'wss://mqtt.joiibmed.com:2083/mqtt';

export const DATE_TEXT_DETECT_REGEX = /(Monday)|(Tuesday)|(Wednesday)|(Thursday)|(Friday)|(Saturday)|(Sunday)|(January)|(February)|(March)|(April)|(May)|(June)|(July)|(August)|(September)|(October)|(November)|(December)|(Mon)|(Tue)|(Wed)|(Thu)|(Fri)|(Sat)|(Sun)|(mon)|(tue)|(wed)|(thu)|(fri)|(sat)|(sun)/g;
