import { languageSet } from "../language/languageSet";
import { defaultLanguage } from "../lib/config";
const useLang = () => {
  try {
    const  langCode = defaultLanguage;
    const lang = languageSet[langCode] || {}
    // console.log('lang', lang)
    return [lang, langCode];
    
  } catch (error) {
    console.error('useLang - error', error)
    
  }
};
export default useLang;