import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { getCookie, setCookie } from '../../lib/session';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(-1),
  },
}));

export default function LanguageSelect() {
  const classes = useStyles();
  const [language, setLanguage] = React.useState(getCookie('lang'));
  const handleChange = (event) => {
      setLanguage(event.target.value);
      setCookie('lang',event.target.value);
      window.location.reload(true);
    };
  return (
    <div>
      <FormControl className={classes.margin}>
        <div className='mx-4'><strong>Language</strong></div>
        <NativeSelect
          id="demo-customized-select-native"
          value={language}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <option value='en'>English</option>
          <option value='pt'>Portuguese</option>
        </NativeSelect>
      </FormControl>
    </div>
  );
}
